// Generated by robots, do not change this manually!

/** Базовый белый цвет совпадает с whitePrimary */
export const white = 'var(--plasma-colors-white, #FFFFFF)';

/** Первичный белый цвет */
export const whitePrimary = 'var(--plasma-colors-white-primary, #FFFFFF)';

/** Вторичный белый цвет */
export const whiteSecondary = 'var(--plasma-colors-white-secondary, rgba(255, 255, 255, 0.56))';

/** Третичный белый цвет */
export const whiteTertiary = 'var(--plasma-colors-white-tertiary, rgba(255, 255, 255, 0.28))';

/** Базовый черный цвет совпадает с blackPrimary */
export const black = 'var(--plasma-colors-black, #080808)';

/** Первичный черный цвет */
export const blackPrimary = 'var(--plasma-colors-black-primary, #080808)';

/** Вторичный черныйцвет */
export const blackSecondary = 'var(--plasma-colors-black-secondary, rgba(8, 8, 8, 0.56))';

/** Третичный черный цвет */
export const blackTertiary = 'var(--plasma-colors-black-tertiary, rgba(8, 8, 8, 0.28))';

/** Прозрачный цвет */
export const transparent = 'var(--plasma-colors-transparent, rgba(0, 0, 0, 0))';

/** Второстепенная прозрачная */
export const buttonClear = 'var(--plasma-colors-button-clear, #080808)';

/** Дефолтная чёрная, не зависит от темы */
export const buttonBlack = 'var(--plasma-colors-button-black, #080808)';

/** Второстепенная чёрная, не зависит от темы */
export const buttonBlackSecondary = 'var(--plasma-colors-button-black-secondary, rgba(8, 8, 8, 0.12))';

/** Чёрная для использования поверх картинок */
export const buttonBlackTransparent = 'var(--plasma-colors-button-black-transparent, rgba(8, 8, 8, 0.56))';

/** Дефолтная белая, не зависит от темы */
export const buttonWhite = 'var(--plasma-colors-button-white, #FFFFFF)';

/** Второстепенная белая, не зависит от темы */
export const buttonWhiteSecondary = 'var(--plasma-colors-button-white-secondary, rgba(255, 255, 255, 0.12))';

/** Базовый цвет текста, совпадает с primary */
export const text = 'var(--plasma-colors-text, rgba(255, 255, 255, 0.96))';

/** Основной текст в интерфейсе */
export const primary = 'var(--plasma-colors-primary, rgba(255, 255, 255, 0.96))';

/** Второстепенный текст */
export const secondary = 'var(--plasma-colors-secondary, rgba(255, 255, 255, 0.56))';

/** Третичный цвет текста */
export const tertiary = 'var(--plasma-colors-tertiary, rgba(255, 255, 255, 0.28))';

/** Сплошной наборный текст */
export const paragraph = 'var(--plasma-colors-paragraph, rgba(255, 255, 255, 0.96))';

/** Белый в светлой теме / Чёрный в тёмной теме */
export const inverse = 'var(--plasma-colors-inverse, #080808)';

/** Основной бэкграунд всех приложений и смартапов */
export const background = 'var(--plasma-colors-background, #080808)';

/** Первичный цвет фона */
export const backgroundPrimary = 'var(--plasma-colors-background-primary, #171717)';

/** Вторичный цвет фона */
export const backgroundSecondary = 'var(--plasma-colors-background-secondary, #232323)';

/** Третичный цвет фона */
export const backgroundTertiary = 'var(--plasma-colors-background-tertiary, #363636)';

/** Обозначение успешного сценария */
export const success = 'var(--plasma-colors-success, #24B23E)';

/** Цвет предупреждения */
export const warning = 'var(--plasma-colors-warning, #FA6D20)';

/** Цвет ошибки */
export const critical = 'var(--plasma-colors-critical, #FF4D5F)';

/** Цвет фона паранжи */
export const overlay = 'var(--plasma-colors-overlay, rgba(0, 0, 0, 0.8))';

/** Полупрозрачные поверхности */
export const surfaceLiquid01 = 'var(--plasma-colors-surface-liquid01, rgba(255, 255, 255, 0.06))';

/** Полупрозрачные поверхности */
export const surfaceLiquid02 = 'var(--plasma-colors-surface-liquid02, rgba(255, 255, 255, 0.12))';

/** Полупрозрачные поверхности */
export const surfaceLiquid03 = 'var(--plasma-colors-surface-liquid03, rgba(255, 255, 255, 0.2))';

/** Непрозрачные поверхности */
export const surfaceSolid01 = 'var(--plasma-colors-surface-solid01, #171717)';

/** Непрозрачные поверхности */
export const surfaceSolid02 = 'var(--plasma-colors-surface-solid02, #232323)';

/** Непрозрачные поверхности */
export const surfaceSolid03 = 'var(--plasma-colors-surface-solid03, #363636)';

/** Основной фон для карточек */
export const surfaceCard = 'var(--plasma-colors-surface-card, rgba(255, 255, 255, 0.12))';

/** Первичный цвет контролов */
export const buttonPrimary = 'var(--plasma-colors-button-primary, #FFFFFF)';

/** Второстепенная кнопка */
export const buttonSecondary = 'var(--plasma-colors-button-secondary, rgba(255, 255, 255, 0.12))';

/** Кнопка для успешного сценария */
export const buttonSuccess = 'var(--plasma-colors-button-success, #21A038)';

/** Цвет предупреждения у контролов */
export const buttonWarning = 'var(--plasma-colors-button-warning, #E35502)';

/** Цвет ошибки у контролов */
export const buttonCritical = 'var(--plasma-colors-button-critical, #FF2E43)';

/** Цвет зажатого контрола */
export const buttonChecked = 'var(--plasma-colors-button-checked, #FFFFFF)';

/** Цвет фона баблов отправленный сообщений */
export const speechBubbleSent = 'var(--plasma-colors-speech-bubble-sent, rgba(0, 0, 0, 0.28))';

/** Цвет фона баблов получнных сообщений */
export const speechBubbleReceived = 'var(--plasma-colors-speech-bubble-received, rgba(255, 255, 255, 0.12))';

/** Акцентный цвет бренда */
export const accent = 'var(--plasma-colors-accent, #24B23E)';

/** Акцентный цвет у контролов */
export const buttonAccent = 'var(--plasma-colors-button-accent, #21A038)';

/** Цвет рамки фокуса у контрола */
export const buttonFocused = 'var(--plasma-colors-button-focused, #21A038)';

/** Градиент для заливки основного фона */
export const gradient = 'var(--plasma-colors-gradient, linear-gradient(336.84deg, rgba(20, 116, 70, 0.6) 0%, rgba(8, 8, 8, 0) 64.88%), radial-gradient(100% 100% at 75.89% 100%, rgba(0, 133, 255, 0.24) 0%, rgba(0, 71, 255, 0.03) 100%), linear-gradient(180deg, rgba(8, 8, 8, 0) 50%, rgba(7, 71, 33, 0.3) 100%), linear-gradient(270deg, #061621 0%, rgba(8, 8, 8, 0) 100%))';

/** Градиент для заливки основного фона */
export const gradientDevice = 'var(--plasma-colors-gradient-device, linear-gradient(26.05deg, rgba(15, 153, 24, 0.28) 0%, rgba(8, 8, 8, 0) 72.24%), radial-gradient(100% 100% at 0% 100%, rgba(0, 170, 255, 0.24) 0%, rgba(8, 8, 8, 0) 99.69%), radial-gradient(74.68% 149.35% at 50% 149.35%, rgba(0, 102, 255, 0.6) 0%, rgba(8, 8, 8, 0) 99.69%))';

/** Градиент подсказок о голосовых запросах */
export const voicePhraseGradient = 'var(--plasma-colors-voice-phrase-gradient, linear-gradient(45deg, hsla(130, 75.63025210084032%, 46.666666666666664%, 1) 0%, hsla(189.78947368421052, 78.51239669421487%, 52.54901960784314%, 1) 100%))';

