// Generated by robots, do not change this manually!

export const darkEva = {
    ":root": {
        "--plasma-colors-white": "#FFFFFF",
        "--plasma-colors-whitePrimary": "#FFFFFF",
        "--plasma-colors-whiteSecondary": "rgba(255, 255, 255, 0.56)",
        "--plasma-colors-whiteTertiary": "rgba(255, 255, 255, 0.28)",
        "--plasma-colors-black": "#080808",
        "--plasma-colors-blackPrimary": "#080808",
        "--plasma-colors-blackSecondary": "rgba(8, 8, 8, 0.56)",
        "--plasma-colors-blackTertiary": "rgba(8, 8, 8, 0.28)",
        "--plasma-colors-transparent": "rgba(0, 0, 0, 0)",
        "--plasma-colors-buttonClear": "#080808",
        "--plasma-colors-buttonBlack": "#080808",
        "--plasma-colors-buttonBlackSecondary": "rgba(8, 8, 8, 0.12)",
        "--plasma-colors-buttonBlackTransparent": "rgba(8, 8, 8, 0.56)",
        "--plasma-colors-buttonWhite": "#FFFFFF",
        "--plasma-colors-buttonWhiteSecondary": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-text": "rgba(255, 255, 255, 0.96)",
        "--plasma-colors-primary": "rgba(255, 255, 255, 0.96)",
        "--plasma-colors-secondary": "rgba(255, 255, 255, 0.56)",
        "--plasma-colors-tertiary": "rgba(255, 255, 255, 0.28)",
        "--plasma-colors-paragraph": "rgba(255, 255, 255, 0.96)",
        "--plasma-colors-inverse": "#080808",
        "--plasma-colors-background": "#080808",
        "--plasma-colors-backgroundPrimary": "#171717",
        "--plasma-colors-backgroundSecondary": "#232323",
        "--plasma-colors-backgroundTertiary": "#363636",
        "--plasma-colors-success": "#24B23E",
        "--plasma-colors-warning": "#FA6D20",
        "--plasma-colors-critical": "#FF4D5F",
        "--plasma-colors-overlay": "rgba(0, 0, 0, 0.8)",
        "--plasma-colors-surfaceLiquid01": "rgba(255, 255, 255, 0.06)",
        "--plasma-colors-surfaceLiquid02": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-surfaceLiquid03": "rgba(255, 255, 255, 0.2)",
        "--plasma-colors-surfaceSolid01": "#171717",
        "--plasma-colors-surfaceSolid02": "#232323",
        "--plasma-colors-surfaceSolid03": "#363636",
        "--plasma-colors-surfaceCard": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-buttonPrimary": "#FFFFFF",
        "--plasma-colors-buttonSecondary": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-buttonSuccess": "#21A038",
        "--plasma-colors-buttonWarning": "#E35502",
        "--plasma-colors-buttonCritical": "#FF2E43",
        "--plasma-colors-buttonChecked": "#FFFFFF",
        "--plasma-colors-speechBubbleSent": "rgba(0, 0, 0, 0.28)",
        "--plasma-colors-speechBubbleReceived": "rgba(255, 255, 255, 0.12)",
        "--plasma-colors-accent": "#129DFA",
        "--plasma-colors-buttonAccent": "#078CE4",
        "--plasma-colors-buttonFocused": "#078CE4",
        "--plasma-colors-gradient": "linear-gradient(336.9deg, #143787 0%, rgba(8, 8, 8, 0) 64.95%), radial-gradient(66.53% 100% at 73.33% 100%, rgba(110, 5, 193, 0.44) 0%, rgba(53, 19, 149, 0.1) 99.69%), linear-gradient(180.03deg, rgba(8, 8, 8, 0) 50%, rgba(25, 63, 152, 0.41) 99.97%), linear-gradient(270deg, rgba(39, 15, 107, 0.3) 0%, rgba(8, 8, 8, 0) 100%)",
        "--plasma-colors-gradientDevice": "linear-gradient(26.05deg, rgba(26, 140, 255, 0.16) 0%, rgba(8, 8, 8, 0) 72.24%), radial-gradient(100% 100% at 0% 100%, rgba(0, 85, 255, 0.28) 0%, rgba(8, 8, 8, 0) 99.69%), radial-gradient(74.68% 149.35% at 50% 149.35%, rgba(128, 0, 255, 0.48) 0%, rgba(8, 8, 8, 0) 99.69%)",
        "--plasma-colors-voicePhraseGradient": "linear-gradient(45deg, hsla(200.11764705882354, 94.44444444444446%, 64.70588235294117%, 1) 0%, hsla(240, 100%, 83.92156862745098%, 1) 100%)",
        "color": "rgba(255, 255, 255, 0.96)",
        "backgroundColor": "#080808"
    }
};
