type Display1 = {
    fontFamily: any;
    fontWeight: any;
    fontStyle: any;
    fontSize: any;
    letterSpacing: any;
    lineHeight: any;
};

export const display1: Display1 = {
    'fontFamily': 'var(--plasma-typo-display1-font-family, \'SB Sans Text\',sans-serif)',
    'fontWeight': 'var(--plasma-typo-display1-font-weight, 500)',
    'fontStyle': 'var(--plasma-typo-display1-font-style, normal)',
    'fontSize': 'var(--plasma-typo-display1-font-size, 6rem)',
    'letterSpacing': 'var(--plasma-typo-display1-letter-spacing, -0.0190em)',
    'lineHeight': 'var(--plasma-typo-display1-line-height, 6rem)'
};