type Headline3 = {
    fontFamily: any;
    fontWeight: any;
    fontStyle: any;
    fontSize: any;
    letterSpacing: any;
    lineHeight: any;
};

export const headline3: Headline3 = {
    'fontFamily': 'var(--plasma-typo-headline3-font-family, \'SB Sans Text\',sans-serif)',
    'fontWeight': 'var(--plasma-typo-headline3-font-weight, 600)',
    'fontStyle': 'var(--plasma-typo-headline3-font-style, normal)',
    'fontSize': 'var(--plasma-typo-headline3-font-size, 1.25rem)',
    'letterSpacing': 'var(--plasma-typo-headline3-letter-spacing, -0.0250em)',
    'lineHeight': 'var(--plasma-typo-headline3-line-height, 1.5rem)'
};