export const lineHeights = [
    'var(--plasma-typo-lineHeights-0, 0.75rem)',
    'var(--plasma-typo-lineHeights-1, 1rem)',
    'var(--plasma-typo-lineHeights-2, 1.125rem)',
    'var(--plasma-typo-lineHeights-3, 1.25rem)',
    'var(--plasma-typo-lineHeights-4, 1.375rem)',
    'var(--plasma-typo-lineHeights-5, 1.5rem)',
    'var(--plasma-typo-lineHeights-6, 1.75rem)',
    'var(--plasma-typo-lineHeights-7, 2.25rem)',
    'var(--plasma-typo-lineHeights-8, 3.25rem)',
    'var(--plasma-typo-lineHeights-9, 4rem)',
    'var(--plasma-typo-lineHeights-10, 6rem)'
];