// Generated by robots, do not change this manually!

/** Базовый белый цвет совпадает с whitePrimary */
export const white = '#FFFFFF';

/** Первичный белый цвет */
export const whitePrimary = '#FFFFFF';

/** Вторичный белый цвет */
export const whiteSecondary = 'rgba(255, 255, 255, 0.56)';

/** Третичный белый цвет */
export const whiteTertiary = 'rgba(255, 255, 255, 0.28)';

/** Базовый черный цвет совпадает с blackPrimary */
export const black = '#080808';

/** Первичный черный цвет */
export const blackPrimary = '#080808';

/** Вторичный черныйцвет */
export const blackSecondary = 'rgba(8, 8, 8, 0.56)';

/** Третичный черный цвет */
export const blackTertiary = 'rgba(8, 8, 8, 0.28)';

/** Прозрачный цвет */
export const transparent = 'rgba(0, 0, 0, 0)';

/** Второстепенная прозрачная */
export const buttonClear = '#080808';

/** Дефолтная чёрная, не зависит от темы */
export const buttonBlack = '#080808';

/** Второстепенная чёрная, не зависит от темы */
export const buttonBlackSecondary = 'rgba(8, 8, 8, 0.12)';

/** Чёрная для использования поверх картинок */
export const buttonBlackTransparent = 'rgba(8, 8, 8, 0.56)';

/** Дефолтная белая, не зависит от темы */
export const buttonWhite = '#FFFFFF';

/** Второстепенная белая, не зависит от темы */
export const buttonWhiteSecondary = 'rgba(255, 255, 255, 0.12)';

/** Базовый цвет текста, совпадает с primary */
export const text = 'rgba(255, 255, 255, 0.96)';

/** Основной текст в интерфейсе */
export const primary = 'rgba(255, 255, 255, 0.96)';

/** Второстепенный текст */
export const secondary = 'rgba(255, 255, 255, 0.56)';

/** Третичный цвет текста */
export const tertiary = 'rgba(255, 255, 255, 0.28)';

/** Сплошной наборный текст */
export const paragraph = 'rgba(255, 255, 255, 0.96)';

/** Белый в светлой теме / Чёрный в тёмной теме */
export const inverse = '#080808';

/** Основной бэкграунд всех приложений и смартапов */
export const background = '#080808';

/** Первичный цвет фона */
export const backgroundPrimary = '#171717';

/** Вторичный цвет фона */
export const backgroundSecondary = '#232323';

/** Третичный цвет фона */
export const backgroundTertiary = '#363636';

/** Обозначение успешного сценария */
export const success = '#24B23E';

/** Цвет предупреждения */
export const warning = '#FA6D20';

/** Цвет ошибки */
export const critical = '#FF4D5F';

/** Цвет фона паранжи */
export const overlay = 'rgba(0, 0, 0, 0.8)';

/** Полупрозрачные поверхности */
export const surfaceLiquid01 = 'rgba(255, 255, 255, 0.06)';

/** Полупрозрачные поверхности */
export const surfaceLiquid02 = 'rgba(255, 255, 255, 0.12)';

/** Полупрозрачные поверхности */
export const surfaceLiquid03 = 'rgba(255, 255, 255, 0.2)';

/** Непрозрачные поверхности */
export const surfaceSolid01 = '#171717';

/** Непрозрачные поверхности */
export const surfaceSolid02 = '#232323';

/** Непрозрачные поверхности */
export const surfaceSolid03 = '#363636';

/** Основной фон для карточек */
export const surfaceCard = 'rgba(255, 255, 255, 0.12)';

/** Первичный цвет контролов */
export const buttonPrimary = '#FFFFFF';

/** Второстепенная кнопка */
export const buttonSecondary = 'rgba(255, 255, 255, 0.12)';

/** Кнопка для успешного сценария */
export const buttonSuccess = '#21A038';

/** Цвет предупреждения у контролов */
export const buttonWarning = '#E35502';

/** Цвет ошибки у контролов */
export const buttonCritical = '#FF2E43';

/** Цвет зажатого контрола */
export const buttonChecked = '#FFFFFF';

/** Цвет фона баблов отправленный сообщений */
export const speechBubbleSent = 'rgba(0, 0, 0, 0.28)';

/** Цвет фона баблов получнных сообщений */
export const speechBubbleReceived = 'rgba(255, 255, 255, 0.12)';

/** Акцентный цвет бренда */
export const accent = '#24B23E';

/** Акцентный цвет у контролов */
export const buttonAccent = '#21A038';

/** Цвет рамки фокуса у контрола */
export const buttonFocused = '#21A038';

/** Градиент для заливки основного фона */
export const gradient = 'linear-gradient(336.84deg, rgba(20, 116, 70, 0.6) 0%, rgba(8, 8, 8, 0) 64.88%), radial-gradient(100% 100% at 75.89% 100%, rgba(0, 133, 255, 0.24) 0%, rgba(0, 71, 255, 0.03) 100%), linear-gradient(180deg, rgba(8, 8, 8, 0) 50%, rgba(7, 71, 33, 0.3) 100%), linear-gradient(270deg, #061621 0%, rgba(8, 8, 8, 0) 100%)';

/** Градиент для заливки основного фона */
export const gradientDevice = 'linear-gradient(26.05deg, rgba(15, 153, 24, 0.28) 0%, rgba(8, 8, 8, 0) 72.24%), radial-gradient(100% 100% at 0% 100%, rgba(0, 170, 255, 0.24) 0%, rgba(8, 8, 8, 0) 99.69%), radial-gradient(74.68% 149.35% at 50% 149.35%, rgba(0, 102, 255, 0.6) 0%, rgba(8, 8, 8, 0) 99.69%)';

/** Градиент подсказок о голосовых запросах */
export const voicePhraseGradient = 'linear-gradient(45deg, hsla(130, 75.63025210084032%, 46.666666666666664%, 1) 0%, hsla(189.78947368421052, 78.51239669421487%, 52.54901960784314%, 1) 100%)';

