// Generated by robots, do not change this manually!

import * as colors from './colors';
import * as colorValues from './colors/values';
import * as typography from './typography';
import * as typographyValues from './typographyValues';

export { colors };
export { colorValues };
export { typography };
export { typographyValues };

export const sberPortalScale = 2;
export const sberBoxScale = 2;
export const mobileScale = 1;
export const scalingPixelBasis = 16;

export * from './colors';
export * from './typography';
export * from './typographyValues';
export * from './typo';
export * from './themes';
