type Body3 = {
    fontFamily: any;
    fontWeight: any;
    fontStyle: any;
    fontSize: any;
    letterSpacing: any;
    lineHeight: any;
};

export const body3: Body3 = {
    'fontFamily': 'var(--plasma-typo-body3-font-family, \'SB Sans Text\',sans-serif)',
    'fontWeight': 'var(--plasma-typo-body3-font-weight, 700)',
    'fontStyle': 'var(--plasma-typo-body3-font-style, normal)',
    'fontSize': 'var(--plasma-typo-body3-font-size, 1rem)',
    'letterSpacing': 'var(--plasma-typo-body3-letter-spacing, -0.0190em)',
    'lineHeight': 'var(--plasma-typo-body3-line-height, 1.25rem)'
};