type Headline4 = {
    fontFamily: any;
    fontWeight: any;
    fontStyle: any;
    fontSize: any;
    letterSpacing: any;
    lineHeight: any;
};

export const headline4: Headline4 = {
    'fontFamily': 'var(--plasma-typo-headline4-font-family, \'SB Sans Text\',sans-serif)',
    'fontWeight': 'var(--plasma-typo-headline4-font-weight, 700)',
    'fontStyle': 'var(--plasma-typo-headline4-font-style, normal)',
    'fontSize': 'var(--plasma-typo-headline4-font-size, 1.25rem)',
    'letterSpacing': 'var(--plasma-typo-headline4-letter-spacing, -0.0250em)',
    'lineHeight': 'var(--plasma-typo-headline4-line-height, 1.5rem)'
};