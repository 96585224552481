type Paragraph1 = {
    fontFamily: any;
    fontWeight: any;
    fontStyle: any;
    fontSize: any;
    letterSpacing: any;
    lineHeight: any;
};

export const paragraph1: Paragraph1 = {
    'fontFamily': 'var(--plasma-typo-paragraph1-font-family, \'SB Sans Text\',sans-serif)',
    'fontWeight': 'var(--plasma-typo-paragraph1-font-weight, 400)',
    'fontStyle': 'var(--plasma-typo-paragraph1-font-style, normal)',
    'fontSize': 'var(--plasma-typo-paragraph1-font-size, 1rem)',
    'letterSpacing': 'var(--plasma-typo-paragraph1-letter-spacing, -0.0190em)',
    'lineHeight': 'var(--plasma-typo-paragraph1-line-height, 1.375rem)'
};